import React from 'react'
import userlogo from '../../assests/images/profile.png'
import { MdDashboard, MdPendingActions, MdPayment, MdLeaderboard, MdImageAspectRatio, MdSecurity, MdOutlineIndeterminateCheckBox } from "react-icons/md";
import { FaArrowRightToCity } from "react-icons/fa6";
import { BiChevronRight, BiClinic } from "react-icons/bi";
import { MdOutlineEmojiTransportation } from "react-icons/md";
import { FaRegUser, FaRegBuilding } from "react-icons/fa";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link, useLocation, NavLink } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { RiContactsFill, RiCoupon3Fill, RiCouponLine, RiHealthBookFill } from 'react-icons/ri';
import { SiHelpdesk } from "react-icons/si";
import { IoMdInformationCircleOutline } from 'react-icons/io';
// import { NavLink } from 'react-bootstrap';

export default function SuperAdminSidebar() {
    return (
        <>
            <div id="dashboard-sidebar" >
                <div className=" profile-header ">
                    <div className=' d-flex justify-content-center sidebar-logo-section '>
                        <img src={userlogo} alt="img" className='img-fluid user-img' />
                    </div>
                    <h5 className='text-center mt-2'>Admin</h5>
                </div>
                <div className="offcanvas-body profile-sidebar-body ">
                    <PerfectScrollbar>
                        <Accordion >
                            <Accordion.Item className='acc_btn' eventKey="0">
                                <Link to="../dashboard">   <Accordion.Header >  <MdDashboard className='mb-1 me-2' size={20} /> Dashboard</Accordion.Header> </Link>

                            </Accordion.Item >
                            <Accordion.Item className='acc_btn' eventKey="1">
                                <Link to="../banner">   <Accordion.Header >  <MdImageAspectRatio className='mb-1 me-2' size={25} /> Banner</Accordion.Header> </Link>

                            </Accordion.Item >
                            <Accordion.Item className='acc_btn' eventKey="1">
                                <Link to="../city-services">   <Accordion.Header >  <FaArrowRightToCity  className='mb-1 me-2' size={25} /> City Cervices</Accordion.Header> </Link>

                            </Accordion.Item >
                            {/* <Accordion.Item className='acc_btn' eventKey="2">
                                <Link to="../security-deposit">   <Accordion.Header >  <MdSecurity  className='mb-1 me-2' size={25} /> Security Deposit</Accordion.Header> </Link>

                            </Accordion.Item > */}
                            <Accordion.Item className='acc_btn' eventKey="3">
                                <Accordion.Header >  <MdOutlineEmojiTransportation className='mb-1 me-1' size={25} />  Vehicle Management</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            <NavLink to='../category' > <li>  <BiChevronRight className='mb-1 me-0' size={20} />  Category</li></NavLink>
                                            <NavLink to='../vehicle-type'> <li><BiChevronRight className='mb-1 me-0' size={20} />  Type</li></NavLink>
                                            <NavLink to='../cc'>  <li><BiChevronRight className='mb-1 me-0' size={20} />   CC</li></NavLink>
                                            <NavLink to='../brand'>  <li><BiChevronRight className='mb-1 me-0' size={20} />  Brand</li></NavLink>
                                            <NavLink to='../model'>  <li><BiChevronRight className='mb-1 me-0' size={20} />  Model</li></NavLink>
                                            <NavLink to='../vehicle-list'>  <li><BiChevronRight className='mb-1 me-0' size={20} /> Vehicle</li></NavLink>
                                            <NavLink to='../city-list'>  <li><BiChevronRight className='mb-1 me-0' size={20} /> City</li></NavLink>
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className='acc_btn' eventKey="4">
                                <Accordion.Header>  <FaRegUser className='mb-1 me-2' size={20} />  User Management</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            {/* <Link to='../staff-list'>    <li ><BiChevronRight className='mb-1 me-0' size={20} />  Staff   </li></Link> */}
                                            <NavLink to='../vendor-list'><li ><BiChevronRight className='mb-1 me-0' size={20} />   Vendors</li></NavLink>
                                            <NavLink to='../customer-list'><li ><BiChevronRight className='mb-1 me-0' size={20} />   Customers</li></NavLink>
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className='acc_btn' eventKey="5">
                                <Accordion.Header>  <RiHealthBookFill className='mb-1 me-2' size={20} />Booking</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            <NavLink to='../booking-list'><li ><BiChevronRight className='mb-1 me-0' size={20} />Booking List</li></NavLink>
                                            {/* <NavLink to='../customer-list'><li ><BiChevronRight className='mb-1 me-0' size={20} />   Customers</li></NavLink> */}
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className='acc_btn' eventKey="6">
                                <Link to="../coupon">   <Accordion.Header >  <RiCouponLine    className='mb-1 me-2' size={25} /> Coupon</Accordion.Header> </Link>
                            </Accordion.Item >
                            <Accordion.Item className='acc_btn' eventKey="7">
                                <Accordion.Header>  <SiHelpdesk  className='mb-1 me-2' size={20} />Help Desk</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            <NavLink to='../vendor-help-request'><li ><BiChevronRight className='mb-1 me-0 ' size={20} />Vendor Help </li></NavLink>
                                            <NavLink to='../customer-help-request'><li ><BiChevronRight className='mb-1 me-0' size={20} /> Customer Help</li></NavLink>
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className='acc_btn' eventKey="8">
                                <Accordion.Header>  <MdOutlineIndeterminateCheckBox   className='mb-1 me-2' size={20} />CRM</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            <NavLink to='../add-about-us'><li ><BiChevronRight className='mb-1 me-0 ' size={20} />About Us </li></NavLink>
                                            <NavLink to='../add-privacy-policy'><li ><BiChevronRight className='mb-1 me-0' size={20} /> Privacy Policy</li></NavLink>
                                            <NavLink to='../add-term-and-condition'><li ><BiChevronRight className='mb-1 me-0' size={20} /> Term & Condition</li></NavLink>
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className='acc_btn' eventKey="9">
                                <Link to="../contact-enquiry-list">   <Accordion.Header >  <RiContactsFill      className='mb-1 me-2' size={25} /> Contact Enquiry</Accordion.Header> </Link>
                            </Accordion.Item >
                            <Accordion.Item className='acc_btn mb-5' eventKey="10">
                                <Link to="../vendor-enquiry-list">   <Accordion.Header >  <IoMdInformationCircleOutline     className='mb-1 me-2' size={25} /> Vendor Enquiry</Accordion.Header> </Link>
                            </Accordion.Item >
                        </Accordion>
                    </PerfectScrollbar>
                </div>
            </div>

        </>
    )
}
