import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var VenderStatusRes;
export const VenderStatusTodo = createAsyncThunk('VenderStatus', async (data) => {
    console.log("data",data)
    try {
        const res = await axios({
            method: "Put",
            url: process.env.REACT_APP_API_URL + `update_vendor_status/${data.id}`,
            data:{
                "vendor_status" : data.status
            },
            headers: { "Content-Type": "application/json", "Authorization": 'Bearer ' + localStorage.getItem('user_token') }
        })
        return VenderStatusRes = res.data

    } catch (error) {
        return VenderStatusRes = error.response.data
    }
})
