import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { VenderDetailsRes, VenderDetailsTodo } from '../../../redux/slices/user managment/venders/VendersDetails';
import { useDispatch, useSelector } from 'react-redux';
import vendorImg from '../../../assests/images/profile.png'
import Loader from '../../../component/loader/Loader';
import AddButton from '../../../component/buttons/AddButton'; import Swal from 'sweetalert2'
import { VenderStatusRes, VenderStatusTodo } from '../../../redux/slices/user managment/venders/VenderStatusChange';
import { toast } from 'react-toastify';

export default function VendorPopup(props) {
    const { view, setView, vData, bool, setBool } = props;
    const handleClose = () => setView(false);
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const venderData = useSelector(state => state?.VendorDetails?.data?.data?.[0])

    console.log("first",vData)

    useEffect(() => {
        setLoading(true)
        dispatch(VenderDetailsTodo(vData?.fk_user_id)).then(() => details_res())
    }, [vData?.fk_user_id])

    const details_res = () => {
        if (VenderDetailsRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const change_status_active = () => {
        // console.log(props)
        Swal.fire({
            title: "Vendor Active",
            text: "Are You Sure Want To Active This Vendor ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Active'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(VenderStatusTodo({ id: vData?.fk_user_id, status: 'active' })).then(() => act_res())
            }
        })
    }

    const act_res = () => {
        if (VenderStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Vendor",
                'Vendor Activate  Successfully',
                'success'
            )
            setView(false)
        } else {
            toast.error(VenderStatusRes?.message, { position: "bottom-right" })
        }
    }

    const change_status_inc = () => {
        Swal.fire({
            title: "Vendor Inactive",
            text: "Are You Sure Want To Inactive This Vendor ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Inactive'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(VenderStatusTodo({ id: vData?.fk_user_id, status: 'inactive' })).then(() => incact_res())
            }

        })
    }

    const incact_res = () => {
        if (VenderStatusRes?.success) {
            setBool(!bool)
            Swal.fire(
                "Vendor",
                'Vendor Inactive  Successfully',
                'success'
            )
            setView(false)
        } else {
            toast.error(VenderStatusRes?.message, { position: "bottom-right" })
        }
    }

    return (
        <>
            <Modal show={view} onHide={handleClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Approval Vendor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ?
                        <div className='d-flex justify-content-center pt-3 pb-5'>
                            <Loader />
                        </div> :

                        <div className='row'>
                            <div className='col-md-4 '>
                                <div className='basic_info '>
                                    <div className="d-flex justify-content-center workout_profile_section  mb-1">
                                        <img src={venderData?.profile_image === null || venderData?.profile_image === '' ? vendorImg : process.env.REACT_APP_IMAGE_URL + `${venderData?.profile_image}`} alt="userImg" className='img-fluid  profile_img' />
                                    </div>
                                    <h6>{venderData?.vendor_name}</h6>
                                    <p>{venderData?.vendor_email}</p>
                                    <span>( {venderData?.vendor_mobile_num} )</span>
                                </div>
                                <div className='details'>
                                    <div className='mt-2'>
                                        <p>Aadhar Card </p>
                                        <span>{venderData?.adhaar_card_num}</span>
                                        <div  className='aadhar_img_sec2' >
                                            <img src={process.env.REACT_APP_IMAGE_URL + venderData?.adhaar_card} className='img-fluid card_img' alt="Aadhar Card Image" />
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <p>Pan Card </p>
                                        <span>{venderData?.pan_card_num}</span>
                                        <div className='aadhar_img_sec2'>
                                            <img src={process.env.REACT_APP_IMAGE_URL + venderData?.pan_card} className='img-fluid card_img' alt="Pan Card Image" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-8">
                                <div className='details'>
                                    <div className=' mt-2'>
                                        <p>Shop Name </p>
                                        {venderData?.shop_name === '' || venderData?.shop_name === null ? '-' : <span>{venderData?.shop_name}</span>}
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-2'>
                                        <p>Delivery Type  </p>
                                        {venderData?.delivery_type === '' || venderData?.delivery_type === null ? '-' : <span>{venderData?.delivery_type}</span>}
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-2'>
                                        <p>Cash On Delivery  </p>
                                        <span>{venderData?.cash_on_delivery === '0' ? 'No' : "Yes"}</span>
                                    </div>

                                    <div className='d-flex justify-content-between align-items-center mt-2'>
                                        <p>City </p>
                                        <span>{venderData?.CitySchema?.city_name}</span>
                                    </div>
                                    <div className=' mt-2'>
                                        <p>Address </p>
                                        {venderData?.shop_address === "" || venderData?.shop_address === null ? '-' : <span>{venderData?.shop_address}</span>}
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Account Name </p>
                                            {venderData?.account_name === '' || venderData?.account_name === 'null' || venderData?.account_name === null ? '-' : <span>{venderData?.account_name}</span>}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Account Number </p>
                                            {venderData?.account_num === '' || venderData?.account_num === 'null' || venderData?.account_num === null ? '-' : <span>{venderData?.account_num}</span>}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Bank Name </p>
                                            {venderData?.bank_name === '' || venderData?.bank_name === 'null' || venderData?.bank_name === null ? '-' : <span>{venderData?.bank_name}</span>}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>IFSC Code </p>
                                            {venderData?.ifsc_code === '' || venderData?.ifsc_code === 'null' || venderData?.ifsc_code === null ? '-' : <span>{venderData?.ifsc_code}</span>}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Latitude </p>
                                            <span>{venderData?.latitude}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <p>Longitude </p>
                                            <span>{venderData?.longitude}</span>
                                        </div>
                                    <div className='d-flex justify-content-center align-items-center mt-2'>
                                        <div> <Button type='button' onClick={() => change_status_active()} variant="" className='active_btn'>ACTIVE</Button></div>
                                        <div className='ms-3'><Button onClick={() => change_status_inc()} type='button' variant="" className='save_btn'>INACTIVE</Button></div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
